.offcanvas.offcanvas-start.mobile-menu {
  max-width: 80% !important;
  min-width: 250px !important;
  .offcanvas-header {
    border-bottom: 1px solid #d8dbdd;
    align-items: center;
    padding: 10px 20px;
    height: 60px;
    color: var(--Secondary) !important;
  }
  .list-group-item {
    border: none !important;
    padding-right: 0px !important;
    .submenu-toggle {
      position: relative;
      width: 100% !important;
      display: block;
      box-sizing: border-box;
      padding-right: 0px !important;
      font-size: 17px;
      font-weight: 500;
      margin-top: 10px;
      &::after {
        position: absolute;
        font-size: 23px;
        right: 10px;
        font-weight: 300;
        top: 0;
      }
      &.collapsed::after {
        content: "\002B";
      }
      &:not(.collapsed):after {
        content: "\2212";
      }
    }
    .nav-link-mobile {
      font-size: 16px;
    }
  }
}

.tf-collapse-item {
  display: block !important;
  overflow: hidden !important;

  .tf-collapse-content {
    transition: 0.5s;
    overflow: hidden;
  }
}
.tf-sidebar .sidebar-item .showmore-item .fl-item2 {
  display: block !important;
}
.tf-sidebar .sidebar-item .showmore-item2 .fl-item3 {
  display: block;
}
.tf-sidebar .sidebar-item .showmore-item3 .fl-item4 {
  display: block;
}
.tfSubscribeMsg {
  max-height: 0px;
  transition: 0.4s;
  overflow: hidden;
  &.active {
    margin-top: 16px;
    margin-bottom: 16px;
    max-height: 180px;
  }
}
.activeMenu {
  color: var(--Secondary) !important;
}
.home-7 {
  .parent-active {
    &.activeMenu {
      color: var(--Primary) !important;
    }
  }
}
.disabled-active-menu {
  .activeMenu {
    color: var(--Primary) !important;
  }
}
.testimonials-thumb-image {
  width: fit-content !important;
}
.instructors-dashboard.show .dashboard-title {
  display: block !important;
}
@media (max-width: 600px) {
  .wg-pagination {
    gap: 5px;
    li {
      width: 30px;
      min-width: 28px;
      a {
        width: 32px;
        height: 32px;
      }
      &:first-child {
        a {
          width: 40px;
          height: 40px;
        }
      }
      &:last-child {
        a {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.tf-sidebar .sidebar-item .showmore-item .d-none {
  display: none !important;
}
.footer.style-2 .app-down {
  display: none;
}
.footer.style-2 .app-down {
  display: none;
}
.footer.style-3 .footer-sub-element {
  display: none;
}
.footer.style-4 .footer-sub-element {
  display: none;
}
.footer.style-3:not(.style-6) {
  .tf-app-download li a {
    background-color: var(--Primary);
    .icon,
    .app div:first-child,
    .app div:last-child {
      color: var(--White);
    }
  }
}

.footer.style-4 {
  .tf-app-download li a {
    background-color: rgba(255, 255, 255, 0.07);
    .icon,
    .app div:first-child,
    .app div:last-child {
      color: var(--White);
    }
  }
}
.footer-logo-2 {
  display: none;
}
.footer.style-2 {
  .footer-logo-1 {
    display: none;
  }
  .footer-logo-2 {
    display: block;
  }
}
.our-event-item {
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .event-item-sub {
    .item-sub-btn {
      margin-left: 30px;
    }
  }
}
