@mixin flex($alignItem, $justifyContent) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: $alignItem;
    justify-content: $justifyContent;
}
@mixin grid($loop, $num, $row-gap, $col-gap) {
    display: grid;
    grid-template-columns: repeat($loop, $num);
    gap: $row-gap $col-gap;
}
@mixin d-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
@mixin centered() {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
@mixin center($x, $y) {
    -webkit-transform: translate($x,$y);
    -moz-transform: translate($x,$y);
    -ms-transform: translate($x,$y);
    -o-transform: translate($x,$y);
    transform: translate($x,$y);
}

// --------------------------

@mixin  transition1 (){
    -webkit-transition: all 0.1s ease;
    -moz-transition   : all 0.1s ease;
    -ms-transition    : all 0.1s ease;
    -o-transition     : all 0.1s ease;
    transition        : all 0.1s ease;
}
@mixin  transition2 (){
    -webkit-transition: all 0.2s ease;
    -moz-transition   : all 0.2s ease;
    -ms-transition    : all 0.2s ease;
    -o-transition     : all 0.2s ease;
    transition        : all 0.2s ease;
}
@mixin  transition3 (){
    -webkit-transition: all 0.3s ease;
    -moz-transition   : all 0.3s ease;
    -ms-transition    : all 0.3s ease;
    -o-transition     : all 0.3s ease;
    transition        : all 0.3s ease;
}
@mixin  transition4 (){
    -webkit-transition: all 0.4s ease;
    -moz-transition   : all 0.4s ease;
    -ms-transition    : all 0.4s ease;
    -o-transition     : all 0.4s ease;
    transition        : all 0.4s ease;
}
@mixin  transition5 (){
    -webkit-transition: all 0.5s ease;
    -moz-transition   : all 0.5s ease;
    -ms-transition    : all 0.5s ease;
    -o-transition     : all 0.5s ease;
    transition        : all 0.5s ease;
}
