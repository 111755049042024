.tf-social-icon {
  &.style-2 {
    a {
      background-color: var(--bg-4);
    }
  }
  a {
    @include flex(center, center);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--bg-1);
    font-size: 12px;
    line-height: 12px;
  }
}

.breadcrumbs {
  .icon-home {
    font-size: 14px;
  }

  .icon-arrow-right {
    font-size: 8px;
  }
}

.wg-pagination {
  @include flex(center, flex-start);
  flex-wrap: wrap;
  gap: 10px;

  li {
    min-width: 40px;
    text-align: center;
    color: var(--Primary);
    font-size: 14px;

    a {
      @include flex(center, center);
      width: 40px;
      height: 40px;
      gap: 10px;
      border-radius: 40px;
      color: var(--Primary);

      i {
        font-size: 18px;
        @include transition3;
      }
    }

    &:hover {
      a {
        background: var(--bg-1);
      }
    }

    &.active {
      a {
        background: var(--Primary);
        color: #fff !important;
      }

      i {
        color: #fff !important;
      }
    }

    &:last-child,
    &:first-child {
      a {
        border: 1px solid var(--Border);
        width: 61px;
        height: 40px;

        i {
          font-size: 10px;
        }
      }

      &:hover {
        a {
          border: 1px solid var(--Primary);
          background-color: var(--bg-4);
        }
      }
    }

    &:last-child {
      margin-left: 10px;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}

.tf-collapse-item {
  .tf-collapse-icon:before {
    @include transition3();
  }

  &.collapsed {
    .tf-collapse-icon::before {
      transform: rotate(180deg);
    }
  }
}

.recent-item {
  @include d-flex();
  gap: 20px;

  .image {
    width: 70px;
    flex-shrink: 0;
    height: 70px;
    border-radius: 4px;
  }

  .content {
    padding-top: 2px;
  }

  p {
    color: var(--Soft-Text);
  }
}

.tags-list {
  @include d-flex();
  gap: 15px;
  flex-wrap: wrap;

  .tags-item {
    border: 1px solid var(--Border);
    border-radius: 4px;
    @include flex(center, center);
    padding: 0 14px;
    color: var(--Primary);

    &:hover {
      background-color: var(--bg-4);
      border-color: var(--Primary);
    }
  }

  &.style-large {
    .tags-item {
      padding: 10px 24px;
      font-size: 14px;
    }
  }
}

.blockquote {
  margin-top: 50px;
  margin-bottom: 53px;
  border-radius: 8px;
  padding: 52px 60px;
  background-color: var(--bg-4);

  .desc {
    margin-bottom: 13px;
  }

  .name {
    font-weight: 500;
    font-size: 16px;
  }
}

.wrap-list-text-check {
  @include d-flex();
  flex-direction: column;
  gap: 12px;

  .list-text-item {
    .icon {
      font-size: 14px;
      line-height: 11px;
    }
  }
}

.wrap-list-text-dot {
  @include d-flex();
  flex-direction: column;
  gap: 8px;

  .list-text-item {
    padding-left: 16px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--Primary);
      left: 0;
      top: 11px;
    }
  }
}

.profile-item {
  @include d-flex();
  gap: 30px;

  .image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding-top: 10px;

    h5 {
      margin-bottom: 4px;
    }

    .sub {
      margin-bottom: 3px;
    }
  }
}

.post-control {
  a {
    margin-bottom: 3px;
    gap: 5px;

    i {
      font-size: 10px;
    }
  }
}

.review-rating {
  @include flex(center, center);
  gap: 18px;

  .course-rating {
    @include flex(center, center);
    gap: 18px;

    .course-rating {
      @include flex(center, center);
      gap: 7px;

      i {
        font-size: 11px;
      }
    }

    .rating::before {
      position: absolute;
      content: "";
      width: 4px;
      height: 4px;
      background-color: var(--Primary);
      border-radius: 50%;
      top: 11px;
      left: -10px;
    }
  }

  .ratings {
    @include d-flex();
    align-items: center;
    gap: 7px;

    i {
      font-size: 11px;
    }
  }

  .rating::before {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    background-color: var(--Primary);
    border-radius: 50%;
    top: 11px;
    left: -10px;
  }
}

.ratings {
  @include d-flex();
  align-items: center;
  gap: 7px;

  i {
    font-size: 11px;
  }
}

.helpful-wrap {
  @include d-flex();
  align-items: center;
  gap: 34px;
  color: var(--Soft-Text);

  i {
    font-size: 17px;
  }
}

.review-wrap {
  .review-title {
    margin-bottom: 15px;
  }

  .review-item {
    @include d-flex();
    gap: 30px;

    &:not(:last-child) {
      padding-bottom: 23px;
      border-bottom: 1px solid var(--Border);
      margin-bottom: 22px;
    }

    .image {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      padding: 5px;
      border: 1px solid var(--Secondary);
      border-radius: 50%;
      margin-top: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      .text,
      a,
      .ratings,
      h5 {
        margin-bottom: 3px;
      }

      .helpful-wrap {
        margin-top: 5px;
      }
    }
  }

  > a {
    margin-top: 24px;
  }
}

.checkbox-item {
  label {
    padding-left: 26px;
    cursor: pointer;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      &:checked {
        ~ .btn-checkbox {
          background-color: var(--Primary);

          &:after {
            display: block;
          }
        }

        ~ .btn-radio {
          &:after {
            display: block;
          }
        }
      }
    }

    .btn-checkbox {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid var(--Border);

      &:after {
        content: "\e90a";
        font-family: $fontIcon !important;
        position: absolute;
        font-size: 8px;
        top: -7px;
        left: 4px;
        color: var(--White);
        display: none;
      }
    }

    .btn-radio {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid var(--Border);
    }

    .btn-radio::after {
      position: absolute;
      display: none;
      content: "";
      height: 16px;
      width: 16px;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: 3px solid black;
      background-color: var(--White);
    }
  }
}

.add-review-wrap {
  padding: 43px 39px 59px;
  border-radius: 12px;
  border: 1px solid var(--Border);

  .add-review-title {
    margin-bottom: 15px;
  }

  > p {
    margin-bottom: 13px;
  }

  .ratings {
    margin-bottom: 22px;
  }

  .cols {
    margin-bottom: 22px;
  }

  textarea {
    height: 136px;
  }

  .checkbox-item {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.box-icon {
  .icon {
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

/*review-item
-----------------------------------------------------------*/
.review-item {
  @include d-flex();
  gap: 30px;

  .avatar {
    height: 60px;
    width: 60px;
    padding: 5px;
    border: 2px solid var(--Secondary);
    border-radius: 50%;
    flex: none;
  }

  .comment-box {
    @include d-flex();
    flex-direction: column;
    gap: 3px;
    color: var(--Primary);

    .author-name {
      font-weight: 500;
    }

    .evaluate {
      font-weight: 500;
      font-size: 14px;
    }

    .comment {
      font-size: 14px;
      padding-bottom: 8px;
    }

    .reaction {
      @include d-flex();
      gap: 30px;

      li {
        display: inline-flex;
        align-items: center;
        color: var(--Soft-Text);
        gap: 10px;
        cursor: pointer;

        &.active {
          color: var(--Secondary);
        }
      }
    }
  }

  &.style-reply {
    .tf-btn {
      padding: 0px 14px;
      font-size: 14px;
      background-color: var(--bg-4);
      color: var(--Primary);
    }

    .space-comment {
      position: relative;
      transition-timing-function: linear !important;

      .form-controls {
        resize: none;
        height: 150px;
        font-size: 14px;
        line-height: 28px;
        padding-top: 10px;
        padding-left: 19px;
        margin-top: 16px;
      }

      .btn-send {
        position: absolute;
        @include flex(center, center);
        height: 50px;
        width: 50px;
        cursor: pointer;
        top: 23%;
        right: 2%;
        border-radius: 50%;
        background-image: linear-gradient(
          rgba(253, 53, 226, 1),
          rgba(255, 121, 26, 1)
        );

        i {
          color: var(--White);
          font-size: 16px;
        }
      }
    }
  }
}

/*meta
--------------------------*/
.entry-meta {
  @include d-flex();
  flex-wrap: wrap;
  gap: 10px;

  li {
    @include d-flex();
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: var(--Soft-Text);
    height: 18px;
  }
}

/*
instructors-item
*/
.instructors-item {
  @include flex(center, flex-start);
  gap: 20px;
  .image-wrapper {
    max-width: 100px;
    max-height: 100px;
    border-radius: 8px;
    overflow: hidden;
  }

  .entry-content {
    .entry-title {
      font-weight: 500;
    }

    .short-description {
      color: var(--Soft-Text);
    }
  }

  &.style-2 {
    .image-wrapper {
      max-width: 210px;
      max-height: 210px;
    }

    .entry-content {
      @include d-flex();
      flex-direction: column;
      gap: 12px;

      .short-description {
        font-size: 14px;
      }

      .entry-meta {
        li {
          font-size: 14px;
          color: var(--Primary);
        }
      }

      .description {
        font-size: 14px;
      }
    }
  }

  &.style-column {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;

    .entry-meta {
      margin-bottom: 11px;

      li {
        font-size: 14px;
        gap: 8px;
      }
    }

    .entry-title {
      margin-bottom: 2px;
    }

    p {
      margin-bottom: 3px;
    }
  }
}

/*academy-item
----------------------------------------------------------------*/
.wrap {
  @include flex(center, space-between);
}
.academisc-item {
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 40%);
    border-radius: 12px;
  }

  .image-wrap {
    max-height: 260px;
    max-width: 100%;
    border-radius: 12px;
    overflow: hidden;
  }

  .content {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;

    p {
      font-size: 18px;
      padding: 14px 25px;
      font-weight: 500;
      color: var(--White);
    }
  }
}

/*Count-down
------------------------------------------------------------------------*/
.js-countdown {
  .countdown__timer {
    @include d-flex();
    flex-wrap: wrap;
    gap: 10px;

    .countdown__item {
      display: inline-block;
      padding: 14px 38px 40px 38px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        position: relative;

        &::before {
          content: "Days";
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          bottom: 14px;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          line-height: 28px;
        }
      }

      &:nth-child(2) {
        &::before {
          content: "Hours";
        }
      }

      &:nth-child(3) {
        &::before {
          content: "minutes";
        }
      }

      &:nth-child(4) {
        &::before {
          content: "seconds";
        }
      }

      .countdown__value {
        display: inline-block;
        font-size: 36px;
        font-weight: 700;
        line-height: 50px;
        color: var(--White);
        width: 46px;
      }
    }
  }
}

/*widget-counter
  ------------------------------------------------------------------------*/
.counter {
  @include d-flex();
  justify-content: space-between;

  .number-counter {
    text-align: center;
    color: var(--Primary);

    .number-image {
      padding-bottom: 32px;
    }

    .counter-content {
      font-size: 26px;
      font-weight: 700;
      line-height: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  &.style-2 {
    flex-wrap: wrap;
    gap: 23px 30px;
    justify-content: flex-start;

    .number-counter {
      padding-left: 18px;
      text-align: start;
      border-left: 2px solid var(--Secondary);
      min-width: 250px;

      .counter-content {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }
  }
  &.style-3 {
    .number-counter {
      text-align: left;
      .counter-content {
        margin-bottom: 8px;
        font-size: 26px;
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
}

/* wg-Filter
  ----------------------------------------------------------------
  */

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.group-filter {
  @include d-flex();
  gap: 15px;
  flex-wrap: wrap;
  .wg-filter {
    .tf-btn {
      background-color: var(--White);
      color: var(--Primary);
      padding: 8px 22px;
      border-color: var(--Border);
      font-size: 14px;
    }
  }
  .tf-btn,
  .nice-select {
    &:hover,
    &.active {
      background-color: var(--bg-4);
      border-color: var(--Primary);
    }
  }

  .nice-select {
    cursor: pointer;
    position: relative;
    padding: 9px 20px !important;
    border-radius: 8px;
    border: 1px solid var(--Border) !important;

    .current {
      @include flex(center, space-between);
      gap: 8px;
      font-weight: 500;
      font-size: 14px;
      i {
        font-size: 6px;

        &::before {
          transform: rotate(0deg);
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .list {
      width: 310px;
      position: absolute;
      padding: 18px 7px 12px 9px;
      background-color: var(--White);
      border-radius: 4px;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      @include transition3();

      .option {
        position: relative;

        .content {
          @include flex(center, space-between);
          width: 100%;
          padding: 3px 0 5px;
        }

        &.selected {
          &::after {
            background-color: var(--Primary);
          }

          &::before {
            position: absolute;
            content: "\e90a";
            font-family: "icomoon" !important;
            left: 16px;
            height: 20px;
            line-height: 18px;
            z-index: 1;
            color: #f5f6ff;
          }
        }
      }

      &.style-radio {
        .option {
          &::after {
            border-radius: 4px;
          }

          &.selected {
            &::after {
              border: 4px solid var(--Primary);
            }
          }
        }
      }
    }

    &.open {
      border: 1px solid var(--Primary) !important;
      background-color: var(--bg-4);

      .current {
        i {
          &::before {
            transform: rotate(-180deg);
          }
        }
      }

      .list {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*shop-single
  ---------------------------------------------------*/

.shop-single {
  .widget-content-image {
    height: 100%;
    padding: 59px 97px;
    border-radius: 16px;
    border: 1px solid var(--Border);

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .shop-detail-content {
    padding: 116px 0 116px 80px;

    .ratings {
      margin-bottom: 21px;
    }

    .product-title {
      margin-bottom: 5px;
      font-weight: 700;
    }

    .author {
      margin-bottom: 12px;
      color: var(--Soft-Text);
    }

    .tf-price {
      margin-bottom: 20px;
      @include flex(center, flex-start);
      gap: 10px;

      .price-on-sale {
        color: var(--Secondary);
        font-weight: 500;
        font-size: 26px;
        line-height: 28px;
      }

      .compare-at-price {
        color: var(--Soft-Text);
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        text-decoration: line-through;
      }
    }

    .description {
      font-size: 14px;
      margin-bottom: 13px;
      color: var(--Primary);
    }

    .tf-btn {
      width: 260px;
      padding: 6px 26px !important;

      i {
        font-size: 20px;
      }
    }

    .product_meta {
      .item {
        @include d-flex();
        gap: 20px;
        color: var(--Primary);

        p {
          font-size: 14px;
        }

        > p:first-child {
          width: 91px;
        }
      }
    }
  }
}

/*Icon-box
    ---------------------------------------------------*/
.icons-box {
  text-align: center;

  .icons {
    display: inline-block;
    position: relative;

    &.has-ellipse {
      margin-bottom: 22px;

      &::after {
        content: "";
        position: absolute;
        top: 28px;
        left: 2px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--bg-4);
        z-index: -1;
      }
    }
  }
  .content {
    h4 {
      margin-bottom: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  &.style-2 {
    @include flex(center, flex-start);
    gap: 20px;
    padding: 19px;
    border: 1px solid var(--Border);
    border-radius: 12px;
    transition: all 0.3s ease-in-out;

    .icons {
      width: 50px;
      height: 50px;
      @include flex(center, center);
    }
    &:hover {
      box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
    }
  }

  &.style-3 {
    @include flex(center, flex-start);
    text-align: start;
    gap: 20px;
    &.text-p {
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .icons {
      font-size: 24px;
      padding: 18px;
      height: 60px;
      width: 60px;
      color: var(--White);
      border-radius: 24px;
      background-color: rgba(255, 255, 255, 0.15);
    }

    p {
      font-size: 16px;
      color: var(--White);
    }
    span {
      color: var(--White);
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  &.style-4,
  &.style-5 {
    .icons {
      font-size: 30px;
      padding: 20px;
      height: 70px;
      width: 70px;
      border-radius: 120px;
      background-color: var(--bg-4);
    }
  }
  &.style-4 {
    @include flex(center, flex-start);
    text-align: start;
    padding: 40px 36px 38px;
    gap: 20px;
    border: 1px solid var(--Border);
    border-radius: 8px;

    .content {
      font-size: 16px;
      font-weight: 400;

      span {
        color: var(--Secondary);
      }
    }
  }

  &.style-5 {
    text-align: start;
    padding: 38px 37px 30px 50px;
    border-radius: 12px;
    border: 1px solid var(--Border);
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0px 4px 6px 0px rgba(64, 79, 104, 0.05);
    }
    .icons {
      margin-bottom: 28px;
    }
    .content {
      h4 {
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
  }
  &.style-6 {
    text-align: left;
    .icons {
      margin-bottom: 12px;
    }
    .content {
      h5 {
        margin-bottom: 3px;
      }
    }
  }
  &.style-7 {
    text-align: center;
    .icons {
      margin-bottom: 22px;
    }
    .content {
      h5 {
        margin-bottom: 3px;
      }
    }
  }
  &.style-8 {
    .icons {
      &.has-ellipse {
        &::after {
          background-color: var(--White);
          z-index: 1;
        }
        svg {
          z-index: 2;
          position: relative;
        }
      }
    }
  }
}

/*List-text-check
    ----------------------------------------------------
    */
.wrap-list-text-check1 {
  @include d-flex();

  li {
    @include d-flex();
    align-items: center;
    gap: 15px;
    font-size: 14px;
    font-weight: 400;

    i {
      display: inline-block;
      text-align: center;
      line-height: 20px;
      width: 20px;
      height: 20px;
      background-color: var(--Primary);
      border-radius: 50%;
      color: var(--White);
    }
  }

  li:not(:last-child) {
    margin-right: 50px;
  }
}

.wrap-list-text-check1 {
  &.style-column {
    flex-direction: column;

    li:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

/*page-404-wrap
    -----------------------------------------*/
.page-404-wrap {
  padding: 60px 0;

  .thumds {
    max-width: 768px;
    margin-bottom: 30px;
  }

  .errors-404-content {
    color: var(--Primary);

    h3 {
      font-weight: 500;
      color: var(--Primary);
      margin-bottom: 30px;
    }

    p {
      font-size: 16px;
      margin-bottom: 30px;
      font-weight: 400;
    }
  }
}

.form-newsletter {
  @include flex(center, center);
  flex-direction: column;
  text-align: center;

  .icon {
    @include flex(center, center);
    background-color: var(--Secondary);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-bottom: 39px;
    font-size: 30px;
    color: var(--White);
  }
  h2 {
    margin-bottom: 9px;
  }
  p {
    margin-bottom: 41px;
  }
  .form-subscribe {
    width: 100%;
    max-width: 600px;
    padding-bottom: 0;
    border: none;
    box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
    border-radius: 120px;
    .name {
      input {
        border-radius: 120px;
        height: 70px;
        border: 1px solid var(--Border);
        padding: 8px 160px 8px 29px !important;
      }
    }

    .button-submit {
      top: 50%;
      transform: translateY(-50%);
      right: 10px;

      .tf-btn {
        height: 50px;
        max-width: 147px;
        border-radius: 120px;
        padding: 15px 20px;
        i {
          font-size: 20px;
        }
      }
    }
  }
}

/*Wrap-video
    -------------------------------*/
.widget-video {
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  .popup-youtube {
    @include flex(center, center);
    @include centered();
    position: absolute;
    padding: 22px;
    border-radius: 25px;
    background-color: var(--White);
  }
}

/*about*/
.flat-about {
  padding: 160px 0 150px;

  .heading-content {
    .box-sub-tag {
      margin-bottom: 18px;
    }
    h2 {
      font-weight: 700;
    }
  }

  .content-right {
    @include d-flex();
    height: 100%;
    justify-content: flex-end;
    p {
      max-width: 448px;
      font-size: 14px;
      text-align: end;
      margin-top: auto;
    }
  }

  .inner {
    margin-top: 47px;
    @include grid(6, 1fr, 30px, 30px);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "aa aa bb bb cc dd"
      "ee ff bb bb gg gg ";

    .item-1 {
      grid-area: aa;
    }

    .item-2 {
      grid-area: bb;
    }

    .item-3 {
      grid-area: cc;
    }

    .item-4 {
      grid-area: dd;
    }

    .item-5 {
      grid-area: ee;
    }

    .item-6 {
      grid-area: ff;
    }

    .item-7 {
      grid-area: gg;
      @include flex(center, center);
      background-image: linear-gradient(
        rgba(253, 53, 226, 1),
        rgba(255, 121, 26, 1)
      );

      p {
        text-align: center;
        padding: 0 46px;
        font-size: 26px;
        font-weight: 400;
        color: var(--White);
        line-height: 37px;
        letter-spacing: -0.7px;
      }
    }

    .about-item {
      border-radius: 10px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

/*quantity
    ----------------------------------------------------------------
    */
.wg-quantity {
  position: relative;
  width: max-content;

  .btn-quantity {
    @include flex(center, center);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 27px;
    height: 27px;
    background-color: var(--White);
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;

    &.minus-btn {
      left: 10px;
    }

    &.plus-btn {
      right: 10px;
    }
  }

  input {
    padding: 11px 15px !important;
    max-width: 127px !important;
    text-align: center;
  }
}

.manh-work {
  padding: 20px 0px;
}

.box-agent {
  @include d-flex();
  flex-wrap: wrap;
  gap: 15px;

  .agent-img-list {
    display: flex;
    align-items: center;

    .agent-img-item {
      height: 50px;
      width: 50px;
      border: 1px solid var(--Secondary);
      border-radius: 50px;
      padding: 4px;
      background-color: var(--White);
    }

    .agent-img-item:not(:first-child) {
      margin-left: -9px;
    }
  }

  .rate {
    @include d-flex();
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;

    .ratings {
      color: var(--Secondary);
      gap: 10px;

      i {
        font-size: 10px;
      }
    }

    .number-rate {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.style2 {
    .agent-img-item:last-child {
      @include flex(center, center);
      color: var(--White);
      font-size: 14px;
      background-color: var(--Secondary);
      border: 4px solid var(--White);
    }
  }
}

//wd box-sub-tag
.box-sub-tag {
  @include flex(center, flex-start);
  width: max-content;
  padding: 4px 24px 4px 4px;
  border-radius: 120px;
  border: 1px solid var(--Border);
  background-color: var(--White);
  gap: 10px;

  .sub-tag-icon {
    @include flex(center, center);
    height: 30px;
    width: 30px;
    color: var(--Secondary);
    background-color: #fdeee9;
    border-radius: 120px;
  }

  &.style2 {
    background-color: var(--Primary);
    color: white;

    .sub-tag-icon {
      background-color: #43485f;
      color: white;
    }
  }
}
.box-sub-tag-item {
  @include flex(center, flex-start);
  width: max-content;
  .sub-tag-item {
    @include flex(center, flex-start);
    padding: 10px 20px 10px 10px;
    background-color: white;
    color: black;
    border-radius: 120px;
    width: max-content;
    gap: 10px;
    > p {
      letter-spacing: 0.4px;
    }

    .sub-tag-icon {
      color: white;
      background-color: var(--Secondary);
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 13px;

      .flaticon-check {
        @include flex(center, center);
        &.style3 {
          border: none;
          .sub-tag-item {
            @include flex(center, flex-start);
            padding: 10px 20px 10px 10px;
            background-color: white;
            color: black;
            border-radius: 120px;
            gap: 10px;

            .sub-tag-icon {
              color: white;
              background-color: var(--Secondary);

              .flaticon-check {
                @include flex(center, center);
              }
            }
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.tags-list {
  .tag-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .tag-list-item {
      border-radius: 4px;
      border: 1px solid var(--Border);
      padding: 0px 14px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      a {
        color: var(--Primary);
      }
    }

    .tag-list-item:hover {
      background-color: var(--bg-4);
      border-color: var(--Primary);
    }
  }

  &.style2 {
    .tag-list {
      .tag-list-item {
        border-radius: 120px;
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  &.style3 {
    .tag-list {
      .tag-list-item {
        border-radius: 120px;
        background-color: rgba(255, 255, 255, 0.7);
        border-color: var(--Primary);

        &:hover {
          background-color: var(--bg-4);
        }
      }
    }
  }
}

.event-item {
  @include d-flex();
  background-color: #f5f6ff;
}

.events-item {
  @include d-flex();
  justify-content: space-between;
  padding: 37px 40px 32px;
  border-radius: 12px;
  gap: 20px;
  background-color: var(--White);
  width: 100%;
  align-items: center;

  .event-item-content {
    .event-item-sub {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 18px;

      .item-sub-address {
        @include flex(center, center);
        gap: 10px;
        white-space: nowrap;

        .flaticon-location {
          display: flex;
        }
      }

      .item-sub-address::after {
        content: "";
        width: 1px;
        height: 100%;
        height: 16px;
        background-color: black;
      }

      .item-sub-time {
        @include flex(center, center);
        gap: 10px;

        .flaticon-clock {
          display: flex;
        }
      }
    }

    .event-item-title {
      margin-bottom: 15px;
    }

    .tf-btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: var(--Primary);
      border: none;
      padding: 0px;
      background-color: transparent;
    }
  }

  .event-item-date {
    padding: 12px 40px 9px 40px;
    background-color: var(--bg-4);
    color: var(--Primary);
    margin: 4px 0px 7px 0px;
    border-radius: 8px;
  }

  &.style2 {
    justify-content: flex-start;
    padding: 0px;
    flex-direction: column;
    gap: 0;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--Border);
    .event-item-img {
      @include d-flex();
      justify-content: flex-end;
      position: relative;
      margin: -1px -1px 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .event-item-date {
        @include flex(center, center);
        flex-direction: column;
        padding: 15px 20px 11px;
        position: absolute;
        margin: 10px;
        .date-text {
          line-height: 28px;
        }
        h2 {
          font-size: 26px;
        }

        h6 {
          font-size: 14px;
        }
      }
    }

    .event-item-content {
      padding: 13px 20px 4px;
      @include flex(flex-start, center);
      flex-direction: column;
      .event-item-sub {
        margin-bottom: 10px;

        .item-sub-time {
          white-space: nowrap;
        }
      }
    }
  }

  &.style3 {
    display: flex;
    gap: 20px;
    justify-content: flex-start;

    .event-item-img {
      flex: none;

      img {
        height: 120px;
        width: 120px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .event-item-content {
      @include flex(flex-start, center);
      gap: 5px;

      .item-sub-time::after {
        content: "";
        width: 1px;
        height: 100%;
        height: 16px;
        background-color: black;
      }

      .item-sub-day {
        @include flex(center, center);
        gap: 10px;
      }
    }
  }
  &.style4 {
    flex-wrap: nowrap;
    border-radius: 8px;
    padding: 42px 40px 38px;
    gap: 22px;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
    .event-item-img {
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .event-item-content {
      .event-item-sub {
        margin-bottom: 9px;
        .item-sub {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          white-space: nowrap;
          position: relative;
          &:not(:last-child):after {
            content: "";
            width: 1px;
            height: 100%;
            height: 16px;
            background-color: black;
          }
        }
      }
      .event-item-title {
        margin-bottom: 5px;
      }
    }
  }
}

.pricing-item {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  white-space: nowrap;
  max-width: 420px !important;
  gap: 0px !important;
  padding: 28px 40px 40px 40px;
  .pricing-price {
    padding-bottom: 31px;
    border-bottom: 1px solid var(--Border);
    .price-month {
      @include flex(flex-end, flex-start);
      margin-bottom: 4px;
      h2,
      h6 {
        font-family: Outfit;
      }
    }
  }

  .pricing-benefit-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 35px 0px 34px 0px;

    .benefit-item {
      @include flex(center, flex-start);
      gap: 10px;
      &:not(:last-child) {
        margin-bottom: 19px !important;
      }
      p {
        font-size: 14px;
      }
      i {
        display: inline-flex;
        height: 17px;
        width: 17px;
        background-color: var(--Secondary);
        border-radius: 12px;
        color: var(--White);
        justify-content: center;
        align-items: center;
        font-size: 7px;
        &::before {
          padding: 0px 10px;
        }
      }
    }
  }
  .tf-btn {
    width: 100%;
  }
  &.basic-plan {
    margin-left: auto;
    padding: 28px 28px 40px 52px !important;
  }
  &.pro-plan {
    margin: 0px auto;
  }
  &.dia-plan {
    padding: 28px 52px 40px 28px !important;
  }
  &.style2 {
    background-color: var(--bg-4);
  }
}

.message-box-item {
  @include flex(center, space-between);
  padding: 20px 30px;
  border-radius: 28px;
  background-color: rgba(205, 233, 246, 0.5);
  color: #4780aa;

  i {
    cursor: pointer;
  }

  &.style2 {
    background-color: rgba(247, 243, 215, 0.5);
    color: #927238;
  }

  &.style3 {
    background-color: rgba(236, 200, 197, 0.5);
    color: #ab3331;
  }

  &.style4 {
    background-color: rgba(222, 242, 215, 0.5);
    color: #5b7052;
  }
}

.login-wrap,
.contact-wrap {
  background-color: var(--bg-4);
  .img-left {
    @include flex(flex-end, flex-start);
    position: relative;
    margin-right: -26px;
    width: 100%;
    height: 760px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      margin-right: -26px;
    }

    .blockquite {
      max-width: 498px;
      position: absolute;
      padding: 60px 0px 50px 60px;
      color: var(--White);
      p {
        font-size: 16px;
      }
      .author {
        padding-top: 28px;
      }
    }
  }

  .content-right {
    background-color: var(--White);
    padding: 74px 60px 63px;
    @include d-flex();
    justify-content: space-between;
    flex-direction: column;
    margin-left: -28px;

    h2 {
      font-family: Cardo;
    }

    .register {
      display: flex;
      padding-bottom: 30px;
      gap: 5px;

      a {
        color: var(--Secondary);
      }
    }

    .form-login,
    .contact-form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 20px;

      .checkbox-item {
        @include flex(center, space-between);
        flex-wrap: wrap;

        a {
          color: var(--Secondary);
        }
      }
    }

    .button-submit {
      @include flex(center, center);
      width: 100%;
      background-color: var(--Primary);
      border-radius: 8px;

      button {
        color: var(--White);
      }
    }

    p {
      text-align: center;
    }

    .login-social {
      @include flex(center, space-between);
      padding-top: 20px;
      gap: 15px;
      flex-wrap: wrap;

      .login-social-icon {
        .tf-btn {
          background-color: transparent;
          @include flex(center, center);
          border-color: var(--Border);
          gap: 5px;
          color: var(--Primary);
        }
      }
    }
  }
}

.tooltips-list {
  display: flex;
  gap: 10px;
  padding: 10px 0px;
  flex-wrap: wrap;

  .tooltips-item {
    div {
      padding: 10px 30px;
      background-color: var(--bg-4);
      border-radius: 8px;
    }
  }
}

.tooltip {
  .tooltip-inner {
    font-family: DM Sans;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
}

.shop-cart-wrap {
  padding: 60px 0;

  .table-shop-cart {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
    margin-bottom: 20px;

    .shop-cart-head {
      display: flex;
      background-color: var(--bg-4);
      border-radius: 8px;
      padding: 21px 30px;
      gap: 10px;
      width: 100%;

      .item {
        font-size: 16px;
        font-weight: 500;
      }

      li:nth-child(1) {
        width: 343px;
      }

      li:nth-child(2) {
        width: 130px;
      }

      li:nth-child(3) {
        width: 130px;
      }

      li:nth-child(4) {
        width: 130px;
      }

      li:nth-child(5) {
        width: 80px;
      }
    }

    .shop-cart-inner {
      display: flex;
      flex-direction: column;
      width: 940px;

      li .shop-cart-item {
        display: flex;
        align-items: center;
        padding: 20px 30px;
        gap: 10px;
        border-bottom: 1px solid var(--Border);

        .cart-item-img {
          width: 50px;
          height: 60px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        li:nth-child(2) {
          width: 282px;
        }

        li:nth-child(3) {
          width: 120px;
        }

        li:nth-child(4) {
          position: relative;
          width: max-content;

          input {
            width: 100px !important;
            text-align: center;
          }
        }

        li:nth-child(5) {
          padding-left: 43px;
          width: 100px;
        }

        li:nth-child(6) {
          @include flex(center, center);
          cursor: pointer;
          margin: 0px 1px 0px 95px;
          height: 30px;
          width: 30px;
          gap: 10px;
          border-radius: 12px;
          border: 1px solid var(--Border);
        }
      }
    }
  }

  .shop-cart-bottom {
    display: flex;
    gap: 30px;
    padding-bottom: 40px;
    white-space: nowrap;
    width: auto;
    .coupon-btn {
      @include d-flex;
      gap: 28px;
    }
  }

  .sidebar-shop {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 40px;
    border: 1px solid var(--Border);

    .cart-total-bill {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-size: 14px;
      }

      .line {
        border-top: 1px solid var(--Border);
      }

      .sub-total,
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .tf-btn {
      background-color: var(--Secondary);
      border-color: transparent;
      padding: 12px 0;
      font-size: 16px;
      color: var(--White);
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.form-checkout {
  h6 {
    margin-bottom: 34px;
  }
  fieldset {
    margin-bottom: 21px;
    label {
      transform: translateY(-25%);
    }
  }
  .cols {
    gap: 30px;
  }

  .tf-select-label {
    padding-top: 20px;
    height: 50px;
    margin-bottom: 18px;
    &::after {
      border-bottom: 1.7px solid #3a3a3c;
      border-right: 1.7px solid #3a3a3c;
      content: "";
      height: 8px;
      width: 8px;
      margin-top: -13px;
      pointer-events: none;
      position: absolute;
      right: 3px;
      top: 31px;
      -webkit-transform-origin: 66% 66%;
      -ms-transform-origin: 66% 66%;
      transform-origin: 66% 66%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    }

    .default {
      padding: 0px 0px 0px;
      margin: 0px 0px 16px;
      width: 100%;
      border-radius: 0px;
      border: none !important;
      border-bottom: 2px solid var(--Border) !important;
      color: var(--Primary);

      option {
        color: var(--Primary);
      }
    }

    &.focused {
      border-color: var(--Primary);

      &::after {
        transform: rotate(-135deg);
      }

      .select-label {
        left: 0px;
        padding: 0px !important;
        background-color: var(--White);
        transform: translateY(-70%);
      }

      .default {
        border-color: var(--Primary) !important;
        color: var(--Primary);
        font-size: 14px;
      }
    }

    .select-label {
      left: 0px;
      top: 15px;
      color: var(--Primary);
      line-height: 20px;
    }
  }

  .sidebar-shop-checkout {
    @include d-flex();
    flex-direction: column;
    gap: 40px;
    max-width: 400px;
    margin-left: auto;

    .sidebar-checkout-item {
      padding: 40px 40px 20px;
      border: 1px solid var(--Border);
      border-radius: 12px;
      &.payment {
        padding: 33px 40px 5px;
      }
      .title {
        margin-bottom: 23px;
      }
      .product-subtotal {
        @include d-flex();
        justify-content: space-between;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--Border);
        margin-bottom: 13px;
      }

      .product-list {
        margin-bottom: 23px;
        li:not(:last-child) {
          margin-bottom: 3px;
        }
        .product-item {
          @include flex(center, space-between);
        }
      }

      .checkout-total-bill {
        @include d-flex();
        flex-direction: column;
        gap: 12px;

        li {
          @include flex(center, space-between);
          padding-bottom: 10px;
          border-bottom: 1px solid var(--Border);

          p {
            font-weight: 500;
          }
        }

        .total p {
          color: var(--Secondary);
        }
      }
    }
    .payment-item {
      p {
        font-size: 14px;
      }
      .descripton {
        margin-bottom: 13px;
      }
    }
    .tf-btn {
      width: 100%;
      background-color: var(--Secondary);
      border-color: transparent;

      a {
        color: var(--White);
        @include flex(center, center);
        gap: 10px;

        i {
          display: inline;
        }
      }
    }
  }
  .shop-checkout-additional {
    margin-top: 32px;
    h6 {
      margin-bottom: 37px;
    }
  }
}

.radio-item {
  label {
    padding-left: 26px;
    cursor: pointer;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      &:checked {
        ~ .btn-radio {
          border: 4px solid black;
          background-color: var(--White);

          &:after {
            display: block;
          }
        }
      }
    }

    .btn-radio {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-color: var(--White);
      border-radius: 50%;
      border: 1px solid var(--Border);
    }
  }
}

.sidebar-course {
  max-width: 400px;
  margin-left: auto;
  border: 1px solid var(--Border);
  border-radius: 12px;
  background-color: var(--white);

  .widget-video {
    margin: 0 auto;
    padding: 20px;
    border-radius: 12px;
  }

  .sidebar-course-content {
    padding: 30px 40px 35px 39px;
    display: flex;
    flex-direction: column;

    .course-price {
      @include d-flex();
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 30px;

      .price {
        @include flex(center, center);
        gap: 10px;

        h3 {
          color: var(--Secondary);
        }

        h6 {
          text-decoration: line-through;
        }
      }

      .sale-off {
        border-radius: 4px;
        padding: 0px 15px;
        border: 1px solid var(--Secondary);
        background-color: var(--bg-4);
        color: var(--Secondary);
      }
    }

    .tf-btn {
      font-weight: 500;
      width: 100%;

      &.add-to-cart {
        margin-bottom: 19px;
      }

      &.buy-now {
        background-color: var(--White);
        color: var(--Primary);
        margin-bottom: 11px;
      }
    }

    .course-text {
      font-size: 14px;
      text-align: center;
      margin-bottom: 13px;
    }

    .course-list {
      ul {
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 9px;

        li {
          @include flex(center, flex-start);
          gap: 9px;

          i {
            font-size: 18px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .course-social {
    text-align: center;
    padding: 20px 0 25px;
    border-top: 1px solid var(--Border);

    h6 {
      padding-bottom: 10px;
    }

    ul {
      justify-content: center;
      display: flex;
      gap: 8px;

      li {
        border: 1px solid var(--Border);
        border-radius: 120px;

        a {
          @include flex(center, center);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          font-size: 12px;

          i {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.box-review {
  padding: 25px 30px 20px 30px;
  background-color: var(--White);
  border-radius: 12px;

  .review-head {
    @include flex(center, flex-start);
    gap: 20px;
    padding-bottom: 8px;

    .head-icon {
      @include d-flex();
      gap: 2px;

      i {
        @include flex(center, center);

        background-color: #00b67a;
        font-size: 17px;
        color: var(--White);
      }
    }

    .head-verified {
      @include flex(center, center);
      gap: 7px;
      color: var(--Soft-Text);

      i {
        @include flex(center, center);
        font-size: 8px;
        height: 17px;
        width: 17px;
        background-color: var(--Soft-Text);
        color: var(--White);
        border-radius: 50%;
      }
    }
  }

  .review-inner {
    padding-bottom: 35px;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.teachers-upskill {
  position: relative;
  .image {
    border-radius: 12px;
    img {
      width: 100%;
      border-radius: 12px;
    }
  }
  .country {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 18px;
    right: 19px;
    border-radius: 120px;
    border: 2px solid var(--White);
    display: flex;
  }
  .name {
    display: flex;
    border-radius: 120px;
    background: var(--White);
    position: absolute;
    bottom: 23px;
    padding: 3px 12px;
    left: 20px;
    gap: 7px;
    align-items: center;
    justify-content: center;
    svg {
      color: var(--Secondary);
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}
.item-event-btn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
  .tf-btn {
    padding: 0px;
    background-color: transparent;
    color: var(--Primary);
    border: unset;
  }
}

.our-event-item {
  @include d-flex();
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;

  .event-item-date {
    @include flex(center, flex-start);
    flex-direction: row;
    gap: 40px;
    padding-right: auto;

    .date-time {
      padding: 11px 30px;
      border-radius: 40px 40px 8px 8px;
      background-color: #f6d6c6;

      .fw-5 {
        color: #eb6644;
      }
    }

    a {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: var(--Primary);
    }
  }

  .event-item-sub {
    @include flex(center, center);
    gap: 10px;
    white-space: nowrap;
    flex-wrap: wrap;

    .item-sub-time {
      @include flex(center, center);
      gap: 7px;

      &:first-child {
        padding-right: 10px;
        border-right: 1px solid var(--Primary);
        height: 17px;
      }
    }

    .tf-btn {
      background-color: var(--White);
      color: var(--Primary);
      border: none;
      font-weight: 500;
    }
  }
  &:hover .event-hover {
    opacity: 1;
    visibility: visible;
  }
  .event-hover {
    width: 200px;
    height: 240px;
    position: absolute;
    left: 50%;
    top: -30%;
    transform: translateX(-50%);
    transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    overflow: hidden;
    z-index: 2;
    visibility: hidden;
  }
  &.style-1 {
    padding-bottom: 19px;
    border-bottom: 1px solid #eeddc3;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .event-item-sub {
      .tf-btn {
        background-color: unset;
        padding: 0px;
      }
    }
  }
}

.progress {
  border-radius: 8px;
  height: 6px;
  margin: 12px 0px;

  .progress-bar {
    width: 80%;
    border-radius: 8px;
    background-color: var(--Secondary);
  }
}

.exam-progress {
  @include flex(center, space-between);
  padding: 0 0 10px 0;
}

.categories-item {
  border-radius: 12px;
  border: 1px solid var(--Border);
  @include d-flex();
  margin: 0 0px 28px 0;
  .categories-item-content {
    gap: 20px;

    .text {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.categories-item-default {
  align-items: center;
  gap: 20px;

  .categories-item-image img {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    max-height: 90px;
    margin: -1px 0 -1px -1px;
  }
}

.categories-item-style-2 {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
  border: 1px solid var(--Border);

  .categories-item-image {
    img {
      height: auto;
      border-radius: 8px;
    }
  }

  .categories-item-content {
    @include d-flex();
    flex-direction: column;
    align-items: center;
  }
}
.categories-item-style-3 {
  display: flex;
  gap: 11.2px;
  align-items: center;
  flex-wrap: wrap;
  .categories-list {
    border-radius: 12px;
    border: 1px solid #e4e4e7;
    display: inline-flex;
    align-items: center;
    gap: 15px;
    padding: 14px 19.1px;
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
  }
}
.categories-item-style-4 {
  border-radius: 12px;
  border: 1px solid var(--Border);
  padding: 10px;
  .categories-image {
    display: block;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 12px;
    img {
      height: 100%;
      min-height: 150px;
      object-fit: cover;
    }
  }
  .categories-label {
    margin-top: 4px;
    margin-bottom: 2px;
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: var(--Primary);
      i {
        margin-left: 7px;
        display: inline-block;
      }
    }
  }
}

.sort-by-wrap {
  margin-top: -8px;
  .sort-wrap {
    flex-wrap: wrap;
    @include d-flex();
    align-items: center;

    .nice-select {
      position: relative;
      padding: 0 16px 0 0 !important;
      cursor: pointer;

      &::after {
        content: "\e902";
        font-size: 6px;
        top: 0%;
        right: 0;
        position: absolute;
        font-family: $fontIcon;
        @include transition3();
      }

      .list {
        position: absolute;
        opacity: 0;
        visibility: hidden;
      }
    }

    .nice-select.open {
      .list {
        opacity: 1;
        visibility: visible;
        background-color: var(--White);
        @include transition3();
        padding: 18px 7px 12px 9px;
        border-radius: 4px;
        .option {
          position: relative;
          &.selected {
            color: var(--Secondary);
            &::after {
              content: "\e90a";
              font-family: $fontIcon;
              position: absolute;
              transform: translateY(-50%);
            }
          }
        }
      }
      &::after {
        content: "\e902";
        font-size: 6px;
        top: 0%;
        rotate: 180deg;
        @include transition3();
      }
    }
  }

  .text-1 {
    font-size: 14px;
  }

  .text-2 {
    padding: 0 7px 0 40px;
    font-size: 14px;
    color: var(--Soft-Text);
  }
}

.widget-saying {
  .widget-saying-header {
    @include flex(center, center);
    flex-direction: column;
    padding-bottom: 30px;

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 28px;
      font-weight: 400;
      color: var(--Soft-Text);
    }
  }

  .widget-saying-middle {
    @include d-flex();
    justify-content: space-between;
    align-items: center;
    padding-bottom: 17px;
    gap: 80px;
  }

  .widget-saying-item {
    @include flex(center, center);
    flex-direction: column;

    img {
      height: 80px;
      border-radius: 30px;
      margin-bottom: 34px;
      opacity: 0.5;
    }

    .info {
      @include flex(center, center);
      flex-direction: column;
      opacity: 0;
      visibility: hidden;

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        margin-top: -3px;
        margin-bottom: 21px;
      }

      .name {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .swiper-slide.swiper-slide-active {
    img {
      opacity: 1;
      visibility: visible;
      height: 120px;
      border-radius: 40px;
      @include transition3();
    }

    .info {
      opacity: 1;
      visibility: visible;
      @include transition3();
    }
  }

  .widget-saying-content {
    p {
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      line-height: 45px;
    }
  }

  .swiper-slide.swiper-slide-prev {
    img {
      height: 100px;
      border-radius: 35px;
      @include transition3();
    }
  }

  .swiper-slide.swiper-slide-next {
    img {
      height: 100px;
      border-radius: 35px;
      @include transition3();
    }
  }
}

.browse-course-banner {
  position: relative;
  @include d-flex();
  align-items: center;
  padding: 40px;
  background-color: var(--bg-4);
  border-radius: 24px;
  gap: 40px;

  .icon {
    padding: 14.5px 19px;
    @include flex(center, center);
    border-radius: 50%;
    background-color: var(--Secondary);

    > i {
      color: var(--bg-4);
      font-size: 25.63px;
    }
  }

  .browse-course-banner-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  > a {
    position: absolute;
    right: 40px;
    border-radius: 120px;
  }
}

.instagram-item {
  border-radius: 12px;
  overflow: hidden;
  &:hover .image-wrap:before {
    opacity: 0.6;
    visibility: visible;
    z-index: 1;
  }
  .image-wrap {
    display: block;
    position: relative;
    &:before {
      background: var(--Secondary);
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      content: "";
      position: absolute;
      transition: all 0.4s ease-in-out;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.tags {
  @include d-flex();
  flex-direction: column;
  gap: 20px;
  margin-bottom: 35px;

  .tag {
    @include d-flex();
    align-items: center;
    gap: 10px;
    i {
      font-size: 18px;
    }
  }
}

.badge-item {
  @include d-flex();
  align-items: center;
  padding: 30px 56px 30px 30px;
  gap: 23px;
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  border-radius: 12px;
  background-color: var(--White);

  .badge-item-icon {
    padding: 23px 24px;
    @include flex(center, center);
    border-radius: 50%;

    i {
      color: var(--White);
      font-size: 14px;
    }
  }

  .content {
    .title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: -2px;
      letter-spacing: -1.3px;
    }

    .text {
      font-size: 11px;
      line-height: 28px;
      color: var(--Soft-Text);
    }
  }
}

.badge-item-default {
  .badge-item-icon {
    background-color: var(--bg-2);
  }
}

.badge-item-style-2 {
  .badge-item-icon {
    background-color: var(--Secondary);
  }
}

.widget-learn-wrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 52px;
}

.learn-item-default {
  gap: 44px;
  padding: 17px 19px;
  border-radius: 8px;
  background-color: var(--White);
  .image-box {
    @include d-flex();
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-bottom: 15px;
  }
  p {
    color: var(--Primary);
    text-align: center;
  }
}

.learn-item-style-2 {
  text-align: center;
  & > a {
    margin-bottom: 13px;
    display: block;
    img {
      height: 56px;
      width: 56px;
      border-radius: 100%;
    }
  }
}

.campus-life-item {
  img {
    margin-bottom: 20px;
    border-radius: 8px;
  }

  a {
    @include d-flex();
    align-items: center;
    gap: 10px;

    h4 {
      font-weight: 500;
      color: var(--White);
    }

    i {
      color: var(--White);
    }
  }
}

.we-offer-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 37.33px;
}
.offer-item {
  @include d-flex();
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .offer-item-img {
    img {
      border-radius: 200px 200px 8px 8px;
    }
  }

  .offer-item-content {
    text-align: center;
    h4 {
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
    }
  }
}

.instructors-teacher-wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
}
.instructors-teacher-item {
  .instructors-img {
    margin-bottom: 13px;
    img {
      border-radius: 200px 200px 8px 8px;
    }
  }

  .instructors-content {
    h4 {
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
    }
  }
}

.author-item {
  @include d-flex();
  align-items: center;
  gap: 13px;

  .author-item-img {
    @include flex(center, center);
    padding: 1px;
    background: linear-gradient(
      to bottom right,
      hsla(17, 73%, 58%, 1),
      hsla(17, 83%, 69%, 1)
    );
    border-radius: 50%;

    img {
      width: 33.33px;
      height: 33.33px;
      border: 3.33px solid var(--White);
      background-color: var(--White);
      border-radius: 50%;
      object-fit: cover;
    }
  }

  a {
    font-size: 14px;
  }

  .text-1 {
    color: var(--Soft-Text);
    font-size: 14px;
  }
}

.program-information-item {
  @include d-flex();
  flex-direction: column;
  gap: 10px;

  .top {
    @include d-flex();
    align-items: center;
    gap: 10px;

    span {
      font-size: 14px;
    }
  }

  .bottom {
    @include d-flex();
    align-items: center;
    gap: 10px;

    .program-information-btn {
      padding: 0 10px;
      color: var(--Secondary);
      background-color: var(--bg-4);
      border: 1px solid var(--Secondary);
      border-radius: 4px;

      &:hover {
        color: var(--White);
        background-color: var(--Secondary);
        @include transition3();
      }
    }
  }
}

.selling-course-item {
  .list {
    @include d-flex();
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 30px 0;
    border-bottom: 1px solid var(--Border);
  }
}

.profile-wrap {
  position: relative;
  @include d-flex();
  align-items: center;
  gap: 30px;
  padding-bottom: 38px;
  border-bottom: 1px solid var(--Border);
  .profile-img {
    @include flex(center, center);
    padding: 2px;
    background: linear-gradient(
      to bottom right,
      hsla(308, 98%, 60%, 1),
      hsla(25, 100%, 55%, 1)
    );
    border-radius: 50%;
    width: 120px;
    height: 120px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      border: 10px solid var(--White);
      background-color: var(--White);
      object-fit: cover;
    }
  }

  .profile-info {
    h4 {
      font-weight: 500;
    }

    p {
      font-size: 14px;
      color: var(--Soft-Text);
    }
  }

  .profile-btn {
    @include d-flex();
    gap: 10px;
    position: absolute;
    right: 15px;

    .btn {
      position: relative;
      font-size: 14px;
      line-height: 28px;
      cursor: pointer;
    }

    .btn-delete {
      color: var(--Secondary);
      border-color: var(--Secondary);

      &:hover {
        color: var(--White);
        background-color: var(--Secondary);
        @include transition3();
      }
    }

    .btn-update {
      border-color: var(--Primary);

      &:hover {
        color: var(--White);
        background-color: var(--Primary);
        @include transition3();
      }
    }
  }

  input {
    display: none;
  }
}
//go to top
.progress-bars {
  .progress-bars-line {
    display: block;
    height: 10px;
    border-radius: 10px;
    background-color: var(--Border);
    > div {
      position: relative;
      display: block;
      height: 10px;
      border-radius: 10px;
      background-color: var(--Primary);
      .progress-bars-number {
        position: absolute;
        right: -3px;
        top: -30px;
      }
    }
  }
}
.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  border-radius: 30px;
  -webkit-box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
  box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  background: var(--Primary);
  &::after {
    position: absolute;
    content: "\e905";
    font-family: $fontIcon;
    rotate: -45deg;
    text-align: center;
    line-height: 50px;
    font-size: 12px;
    color: var(--White);
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
  }
  &.active-progress {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  svg {
    path {
      fill: none;
    }
    &.progress-circle {
      path {
        box-sizing: border-box;
        stroke: var(--Secondary);
        stroke-width: 5;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transition-duration: 0.4s;
        transition-timing-function: linear;
      }
    }
  }
}

/* preload */
/* -------------------------------- */
.preload-container {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999999999999;
  height: 100%;
  width: 100%;
  background-color: var(--White);
}

.preload-container .middle {
  top: 50%;
  left: 50%;
  width: 50px;
  position: absolute;
  z-index: 100;
  margin: -30px 0 0 -30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  // content: url(../images/logo/favicon.png);
  animation: preload 1.2s linear infinite alternate;
}

.wrap-instructor {
  @include d-flex();
  gap: 15px;
  flex-wrap: wrap;
}
.section-categories-course,
.section-categories-topics,
.section-categories-instructors {
  .heading-section {
    margin-bottom: 37px;
    h3 {
      margin-bottom: 16px;
    }
  }
}
.section-categories-course {
  padding-bottom: 48px;
  .widget-tabs,
  .tabs-with-filter {
    .widget-menu-tab {
      margin-bottom: 38px;
      gap: 22px;
      .item-title {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}
.section-categories-topics {
  padding-bottom: 56px;
  .heading-section {
    margin-bottom: 38px;
  }
  .tags-list {
    gap: 10px;
  }
}
.section-categories-instructors {
  padding-bottom: 54px;
  .wrap-instructor {
    .entry-content {
      .entry-meta {
        margin-top: 4px;
        margin-bottom: 5px;
      }
      p {
        line-height: 24px;
      }
    }
  }
}

.top-wrapper {
  @include flex(center, space-between);
  margin-bottom: 40px;
  flex-wrap: wrap;
  .sort-by-wrap {
    .sort-wrap {
      gap: 30px;
      .sort-by {
        @include d-flex();
        .text-2 {
          padding-left: 0;
        }
      }
    }
  }
}

.grid-list-items-4 {
  display: grid;
  gap: 30px 40px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 33px;
}

.page-title {
  &.style-3 {
    .tags-list {
      flex-direction: column;
      &.style3 {
        .tag-list {
          .tag-list-item {
            background-color: rgb(214, 235, 244);
            &:hover {
              background-color: var(--bg-2);
            }
          }
        }
      }
    }
  }
  &.page-help {
    padding: 61px 0 24px;
    .content {
      margin-left: auto;
      margin-right: auto;
      max-width: 925px;
      h6 {
        margin-bottom: 22px;
      }
      form {
        border-radius: 999px;
        overflow: hidden;
        box-shadow: 0px 1px 4px 0px rgba(64, 79, 104, 0.1019607843);
        margin-bottom: 35px;
      }
    }
  }
  &.page-ui-elements,
  &.checkout,
  &.about,
  &.instructor,
  &.event,
  &.become-teacher {
    .content {
      .breadcrumbs {
        margin-top: 7px;
        margin-bottom: 49px;
      }
      h2 {
        margin-bottom: 8px;
      }
    }
  }
  &.become-teacher {
    padding-bottom: 60px;
    h6 {
      margin-bottom: 22px;
    }
  }
}
.grid-list-items-5 {
  display: grid;
  gap: 31px 25px;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 23px;
}
.tf-pagination-wrap {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  p {
    margin-bottom: 12px;
  }
  .progress {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
    height: 2px;
  }
  .tf-btn {
    width: 100%;
    background-color: var(--white);
    color: var(--Primary);
    border-radius: 8px;
  }
}
.loadmore-item {
  .fl-item {
    display: none;
  }
}

.grid-list-items-3 {
  display: grid;
  gap: 31px 40px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 32px;
  &.style-left {
    padding-left: 2px;
  }
}

.list-style-v3,
.list-style-v6 {
  padding: 107px 0 118px;
  background-color: var(--bg-4);
  margin-bottom: 50px;
}
.list-style-v6,
.list-style-v7 {
  .grid-list-items-4 {
    gap: 26.67px;
  }
}
.list-style-v1 {
  padding-bottom: 40px;
}
.list-style-v2 {
  padding-bottom: 54px;
}
.list-style-v4 {
  padding-bottom: 49px;
}
.list-style-v5 {
  padding-bottom: 60px;
}
.list-style-v7 {
  padding-bottom: 48px;
}

.grid-list-items-5,
.grid-list-items-4 {
  &.list {
    margin-bottom: 0px;
  }
}

.checkout-billing {
  .tf-field {
    height: 50px;
  }
}

.section-vison {
  .images {
    border-radius: 12px;
    overflow: hidden;
    max-width: 686px;
    height: 650.02px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .content {
    padding-top: 136.51px;
    padding-bottom: 136.51px;
    .box-sub-tag {
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 8px;
    }
    .text-content {
      margin-bottom: 11px;
      letter-spacing: 0.5px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 7px;
      padding-bottom: 2px;
      font-size: 14px;
      li {
        &::before {
          content: "•";
          color: black;
          font-size: 20px;
          margin-right: 9px;
        }
      }
    }
  }
}
.section-counter {
  &.page-about {
    .counter {
      .counter-content {
        font-size: 26px !important;
        line-height: 28px !important;
        font-weight: 500 !important;
        margin-bottom: 7px !important;
      }
    }
  }
}

.contact-wrap {
  .img-left {
    img {
      border-radius: 12px 0px 0px 12px;
    }
  }
  .content-right {
    background-color: var(--bg-4) !important;
    border-radius: 0 12px 12px 0;
    h2 {
      margin-bottom: 9px;
    }
    .register {
      letter-spacing: 0.5px;
      padding-bottom: 20px !important;
    }
    .contact-form {
      .tf-input {
        background-color: var(--bg-4);
      }
    }
    p {
      text-align: start !important;
    }
  }
}
.section-why {
  &.page-contact {
    .icons-box {
      .icons {
        &.has-ellipse {
          &::after {
            top: 20px;
          }
        }
      }
    }
  }
}

.section-items {
  &.page-help {
    .wrap-icons-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 27px 30px;
      max-width: 1290px;
      margin: 0 auto;
    }
  }
}
.section-faq {
  &.page-help {
    .tf-accordion {
      max-width: 800px;
      margin: 0 auto;
    }
  }
}

.top-wrapper {
  &.page-event,
  &.page-instructor {
    margin-bottom: 38px;
    .group-filter {
      form {
        width: 300px;
      }
    }
  }
}
.section-instructor {
  &.page-instructor {
    .grid-layout-5 {
      gap: 20px 26px;
      margin-bottom: 32px;
    }
    .instructors-item {
      .image-wrap {
        height: 260px;
        border-radius: 4px;
      }
    }
  }
}

.section-event {
  &.page-event {
    background-color: var(--white);
    .grid-layout-4 {
      gap: 28px 28px;
      margin-bottom: 42px;
    }
  }
}

.section-top-course {
  &.page-become-teacher {
    .widget-menu-tab {
      margin-bottom: 60px;
    }
    .content {
      max-width: 447px;
      margin: 0 auto;
      p {
        font-size: 14px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
      }
      .paragraph-1 {
        margin-bottom: 28px;
      }
    }
    .image {
      max-width: 560.5px;
      max-height: 490.34px;
      margin-left: 6px;
      overflow: hidden;
    }
  }
}
.section-counter {
  &.page-become-teacher {
    .wrap-couter {
      padding: 78px 90px 77px;
      background-color: var(--Primary);
      border-radius: 12px;
      .counter {
        .number-counter {
          .counter-content {
            color: var(--White);
          }
          p {
            color: var(--White);
          }
        }
      }
    }
  }
}

.page-title.style-9 {
  margin: 60px auto 0;
  max-width: 1800px;
  border-radius: 24px;
  padding: 120px 186px;
  &.bg-5 {
    background-color: #d6ebf4;
    .author-item .author-item-img img {
      border: 10px solid #d6ebf4;
    }
  }
  &::before {
    background-size: contain;
    top: -100px;
  }

  .author-item .author-item-img img {
    border: 10px solid var(--bg-4);
  }

  .entry-meta {
    li {
      font-size: 14px;
    }
  }
}

.pd-bt-20 {
  padding-bottom: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-16 {
  font-size: 16px;
}

.section-icons {
  padding-bottom: 40px;
}

.pb-13 {
  padding-bottom: 13px;
}

.section-learn {
  padding: 34px 40px 25px 40px;
  border: 1px solid var(--Border);
  border-radius: 12px;

  .wg-pagination {
    padding: 10px;
  }
}

.section-right {
  padding: 40px 40px 40px 40px;
  margin-left: auto;
  max-width: 1000px;
  border: 1px solid var(--Border);
  border-radius: 12px;

  .item-title {
    padding-bottom: 23px;
  }

  .section-inner {
    padding: 10px 0;
    border-radius: 12px;
  }

  .course-item {
    padding: 0px 0 30px 0;
    margin-bottom: 10px;

    img {
      height: 210px;
    }

    .line-clamp-2 {
      margin-bottom: -1px;
    }
  }

  .filter {
    @include d-flex();
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 32px;
  }

  .widget-menu-tab {
    margin-bottom: 40px;
  }

  .shop-checkout {
    padding-bottom: 22px;
  }
}

.section-dashboard-right {
  margin-left: auto;
  max-width: 1000px;

  .section-icons {
    .icons-items {
      @include d-flex();
      gap: 20px;
      padding-bottom: 20px;

      .icons-box {
        width: 320px;
        height: 150px;
      }
    }
  }

  .my-20 {
    margin: -1.7px 0;
  }

  .table-selling-course {
    overflow-x: auto !important;

    &::-webkit-scrollbar {
      width: 0px;
    }

    > * {
      width: 920px;
    }

    > .head {
      padding: 26px 30px 28px;
      border-radius: 16px;
      @include d-flex();
      background-color: var(--bg-4);

      .item {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;

        &:nth-child(1) {
          width: 545px;
        }

        &:nth-child(2) {
          width: 156px;
        }

        &:nth-child(3) {
          width: 120px;
        }

        &:nth-child(4) {
          width: 201px;
          text-align: end;
        }
      }
    }
  }
  .filter {
    border: none;
  }
}

.my-20 {
  margin: 20px 0;
}

.ptable-20 {
  padding: 20px;
}

.section-reviews-right {
  padding: 32px 40px 0 38px;

  .review-item {
    padding: 23px 0 30px 0;
    border-bottom: 1px solid var(--Border);
    gap: 31px;

    &:last-child {
      border: none;
    }
    .avatar {
      margin-top: 8px;
    }
  }
}

.section-wishlist-right {
  padding: 32px 40px 30px 38px;

  .course-item {
    padding: 0px 0 23px 0;
  }

  .filter {
    margin-bottom: 30px;
  }
}

.section-quizzes-right {
  .heading-section {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--Border);
  }

  .list-quizzes {
    margin: 21px 0 -22px 0;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid var(--Border);

    .img-name {
      width: 411px;

      img {
        margin-right: 28px;
        max-width: 100px;
      }
    }

    .icons {
      width: 438px;
    }

    .selling-course-btn {
      width: 72px;
    }

    &:last-child {
      border: none;
    }

    li {
      img {
        max-height: 84px;
      }
    }

    &:first-child {
      padding-top: 20px;
    }
  }

  .meta-item {
    i,
    p {
      color: var(--Soft-Text);
    }
  }

  .box-1 {
    margin-bottom: 60px;
    padding: 32px 40px 23px 38px;

    .table-box-1 {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0px;
      }

      > * {
        width: 920px;
      }
    }
  }

  .box-2 {
    margin-bottom: 60px;
    padding: 32px 40px 38px 38px;

    .table-box-2 {
      overflow-x: auto !important;

      &::-webkit-scrollbar {
        width: 0px;
      }

      > * {
        width: 920px;
      }

      > .head {
        padding: 25px 30px 27px;
        border-radius: 16px;
        @include d-flex();
        background-color: var(--bg-4);
        margin-bottom: 16px;

        .item {
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;

          &:nth-child(1) {
            width: 371px;
          }

          &:nth-child(2) {
            width: 161px;
          }

          &:nth-child(3) {
            width: 161px;
          }

          &:nth-child(4) {
            width: 150px;
          }
        }
      }
    }

    .filter {
      border: none;
      padding-bottom: 20px;
      margin-bottom: 22px;
    }

    .heading-section {
      margin-bottom: 41px;
    }

    .list-head {
      padding: 20px 30px;
      border-radius: 12px;
      margin-bottom: 22px;
    }

    .list {
      .name {
        width: 371px;
      }

      .score {
        width: 159px;
      }

      .attempt {
        width: 160px;
      }

      .finishing-time {
        width: 140px;
      }
    }
  }

  .box-3 {
    padding: 39px 40px 0px 38px;

    .heading-section {
      padding-bottom: 18px;
      margin-bottom: 40px;

      h6 {
        img {
          max-height: 80px;
          border-radius: 4px;
          margin-right: 15px;
        }
      }

      span {
        color: var(--Secondary);
      }
    }

    .line {
      padding-bottom: 15px;
    }

    .quiz {
      padding-bottom: 40px;

      .question {
        padding: 26px 30px;
        background-color: var(--bg-4);
        border-radius: 8px;
      }

      .answers {
        padding-top: 15px;

        &.style-2 {
          @include d-flex();
          flex-direction: column;
          gap: 10px;
        }
      }

      a {
        margin-top: 20px;
        margin-left: auto;
      }

      .radio-item {
        padding: 14px 22px;
      }

      .answer {
        padding: 11px 20px;
        border: 1px solid var(--Border);
        border-radius: 8px;
      }

      .selected {
        border-color: var(--Primary);
      }

      .checkbox-item {
        padding: 14px 20px;
      }
    }
  }

  .list {
    .table-head-title {
      width: 60%;
    }

    .table-head-number-sales {
      width: 20%;
    }

    .table-head-number-amount {
      width: 20%;
    }

    .table-head-action {
      width: 20%;
    }
  }
}

.section-order-right {
  padding: 32px 40px 18px 38px;

  .heading-section {
    margin-bottom: 42px;
  }
}

.table-order {
  overflow-x: auto !important;

  &::-webkit-scrollbar {
    width: 0px;
  }

  > * {
    min-width: 920px;
  }

  > .head {
    padding: 25px 30px 27px;
    border-radius: 16px;
    @include d-flex();
    background-color: var(--bg-4);
    margin-bottom: 16px;

    .item {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;

      &:nth-child(1) {
        width: 102px;
      }

      &:nth-child(2) {
        width: 318px;
      }

      &:nth-child(3) {
        width: 170px;
      }

      &:nth-child(4) {
        width: 171px;
      }

      &:nth-child(5) {
        width: 100px;
      }
    }
  }
}

.section-setting-right {
  padding: 32px 40px 40px 40px;

  .profile-wrap {
    margin-bottom: 30px;
  }

  .item-title {
    padding-bottom: 23px;
  }

  .tf-field {
    padding: 10px 0;
  }
}

.fs-26 {
  font-size: 26px;
}

.fs-14 {
  font-size: 14px;
}

.fw-4 {
  font-weight: 400;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.pb-26 {
  padding-bottom: 26px;
}

.w-full {
  width: 100%;
}

.w-447 {
  width: 447px;
  margin: 0 187px;
}

.w-568 {
  width: 568px;
}

.p-60 {
  padding: 60px;
}

.lh-45 {
  line-height: 45px;
}

.section-add-course-right {
  padding: 30px 40px 40px 40px;

  .widget-menu-tab {
    margin-bottom: 43px;
  }

  .tf-field {
    margin-bottom: 29px;
  }

  .shop-checkout {
    padding-bottom: 6px;
  }

  .section-right .item-title {
    padding-bottom: 23px;
  }

  .style-secondary {
    padding: 12px 135px;
  }

  .tf-accordion-style-3 {
    @include d-flex();
    flex-direction: column;

    .tf-accordion-item {
      padding: 26px 30px 29px 25px;
      border: 1px solid var(--Border);
      border-radius: 4px;
      margin-bottom: 30px;

      .tf-accordion-header {
        position: relative;
        @include d-flex();
        justify-content: space-between;

        .tf-accordion-button {
          position: relative;
          padding: 0 24px;

          &::after {
            position: absolute;
            left: 0;
          }
        }
      }

      .tf-accordion-content {
        .list:first-child {
          border-top: 1px solid var(--Border);
        }

        .list {
          position: relative;
          @include d-flex();
          align-items: center;
          gap: 10px;
          padding: 22px 0px 0px 0px;

          .icon {
            > i {
              font-size: 14px;
            }
          }

          .text {
            font-size: 14px;
          }

          .tf-accordion-btn {
            position: absolute;
            right: 0;
          }
        }

        .btn-add {
          @include d-flex();
          gap: 10px;
          margin-top: 21px;

          .tf-btn {
            padding: 4px 16px;
          }
        }
      }
    }
  }
}

.btn-mobile-instructors {
  background-color: var(--Primary);
  color: var(--White);
  padding: 0 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: fixed;
  left: 0;
  top: 50%;
  display: none;
}

.mobi-instructors-dashboard {
  background-color: var(--White);
  border-radius: 12px;
  padding-bottom: 20px;

  .dashboard-title {
    color: rgba(255, 255, 255, 0.5);
    padding: 21px 30px 11px;
  }

  .dashboard-item {
    padding: 13.5px 30px;
    @include d-flex();
    align-items: center;
    gap: 10px;
    color: var(--Primary);
    font-size: 16px;
    font-weight: 500;
    position: relative;

    i {
      font-size: 20px;
      @include transition3();
    }

    &::before {
      position: absolute;
      content: "";
      left: 0px;
      bottom: 0px;
      top: auto;
      bottom: 0;
      width: 2px;
      height: 0;
      background-color: var(--Secondary);
      @include transition3();
    }

    &.active,
    &:hover {
      background: rgba(255, 255, 255, 0.1);

      &::before {
        height: 100%;
        top: 0px;
        bottom: auto;
      }
    }
  }
}

.selling-course-item {
  @include d-flex();
  align-items: center;

  .image {
    width: 100px;
    height: 80px;
  }

  .title {
    padding-left: 30px;
  }

  > div {
    &:nth-child(2) {
      width: 371px;
    }

    &:nth-child(3) {
      width: 130px;
    }

    &:nth-child(4) {
      width: 197px;
    }

    &:nth-child(5) {
      width: 70px;
    }
  }
}

.order-item {
  @include d-flex();
  align-items: center;
  padding: 19.2px 30px;

  .title {
    padding-left: 30px;
  }

  > div {
    &:nth-child(1) {
      width: 102px;
    }

    &:nth-child(2) {
      width: 318px;
    }

    &:nth-child(3) {
      width: 170px;
    }

    &:nth-child(4) {
      width: 171px;
    }

    &:nth-child(5) {
      width: 100px;
    }
  }
}

.box-2-item {
  padding: 20px;
  @include d-flex();
  align-items: center;

  .image {
    width: 50px;

    img {
      border-radius: 50%;
    }
  }

  .title {
    padding-left: 20px;
  }

  > div {
    &:nth-child(2) {
      width: 333px;
    }

    &:nth-child(3) {
      width: 160px;
    }

    &:nth-child(4) {
      width: 161px;
    }

    &:nth-child(5) {
      width: 140px;
    }
  }
}

.wg-accordions {
  padding-left: 8px;
}

.wg-tabs {
  .widget-tabs,
  .tabs-with-filter {
    padding-bottom: 20px;
    gap: 10px;

    .widget-menu-tab {
      padding-bottom: 20px;

      &.style-2 {
        .item-title {
          padding: 6px 20px;
          font-size: 14px;
        }
      }
    }

    &.style-small {
      .widget-menu-tab {
        margin-bottom: 20px;
        padding: 0;
        gap: 31px;

        .item-title {
          font-size: 14px;
          padding-bottom: 10px;
        }
      }
    }
  }

  .widget-content-inner {
    padding: 0 15px;
  }
}

.widget-tabs,
.tabs-with-filter {
  &.style-2 {
    .widget-menu-tab {
      @include d-flex();
      padding-bottom: 20px;
      gap: 10px;

      .item-title {
        @include transition3();
        position: relative;
        padding: 6px 20px;
        flex-shrink: 0;
        font-size: 14px;
        list-style: 30px;
        font-weight: 500;

        &.active,
        &:hover {
          color: var(--Secondary);
          border: 1px solid var(--Border);

          border-radius: 32px;

          &::after {
            width: 100%;
            left: 0px;
            right: auto;
          }
        }
      }

      .widget-content-inner {
        padding: 0 15px;
      }
    }
  }
}

.wg-table {
  .table-order {
    .head {
      margin: 0;
    }
  }

  .order-item {
    padding: 11px 30px;
  }
}

.wg-message-boxes {
  .message-box-item {
    margin-bottom: 21px;
    padding: 22px 30px;
    border-radius: 16px;
  }
}

.wg-buttons {
  .button-items {
    @include d-flex();
    gap: 43px;
  }
}

.wg-form {
  .wg-radiobox {
    padding-bottom: 18px;
  }

  .wg-checkbox {
    padding-bottom: 16px;
  }

  .wg-switch {
    .head {
      padding-bottom: 13px;
    }

    .switch-items {
      @include d-flex();
      flex-direction: column;
      gap: 20px;
    }
  }

  .tf-select,
  .tf-input.style-1 {
    padding: 24px 0 3px !important;
  }

  .radio-item {
    padding: 7px 0;
  }

  .checkbox-item {
    padding: 7.5px 0;
  }

  .tf-select-label {
    padding-top: 20px;
    height: 50px;
    margin-bottom: 18px;

    &::after {
      border-bottom: 1.7px solid #3a3a3c;
      border-right: 1.7px solid #3a3a3c;
      content: "";
      height: 8px;
      width: 8px;
      margin-top: -13px;
      pointer-events: none;
      position: absolute;
      right: 3px;
      top: 31px;
      -webkit-transform-origin: 66% 66%;
      -ms-transform-origin: 66% 66%;
      transform-origin: 66% 66%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    }

    .default {
      padding: 0 0 0 0;
      margin: 0px 0px 16px;
      width: 100%;
      border-radius: 0px;
      border: none !important;
      border-bottom: 2px solid var(--Border) !important;
      color: var(--Primary);

      option {
        color: var(--Primary);
      }
    }

    &.focused {
      border-color: var(--Primary);

      &::after {
        transform: rotate(-135deg);
      }

      .select-label {
        left: 0px;
        padding: 0px !important;
        background-color: var(--White);
        transform: translateY(-70%);
      }

      .default {
        border-color: var(--Primary) !important;
        color: var(--Primary);
        font-size: 14px;
      }
    }

    .select-label {
      left: 0px;
      top: 15px;
      color: var(--Primary);
      line-height: 20px;
    }
  }
}

.wg-range-slider {
  .heading-section {
    padding-bottom: 7px;
  }
}

.wg-progress-bars {
  .heading-section {
    padding: 10px 0 21px 0;
  }

  .progess-bars-item {
    margin-left: 182px;
  }

  .progress-bars {
    padding-bottom: 10px;

    .progress-bars-line {
      display: block;
      height: 6px;
      border-radius: 10px;
      background-color: var(--Border);

      > div {
        position: relative;
        display: block;
        height: 6px;
        border-radius: 10px;
        background-color: var(--Secondary);

        .progress-bars-number {
          position: absolute;
          right: -3px;
          top: -30px;
        }
      }
    }
  }
}

.wg-typography {
  .head {
    padding-bottom: 10px;
  }

  .desc {
    margin-bottom: 25px;
  }

  .blockquote {
    padding: 44px 60px;
    border-radius: 8px;
    background-color: var(--bg-4);
    margin-bottom: 51px;
  }

  .learn {
    .head {
      padding-bottom: 16px;
    }

    ul {
      li {
        padding-bottom: 12px;
      }
    }
  }
}
