// testimonials-item-------------------
.testimonials-item {
  padding: 40px;
  background-color: var(--White);
  .testimonials-item-header {
    @include d-flex();
    align-items: center;
    padding-bottom: 40px;
    .testimonials-item-img {
      height: 80px;
      width: 80px;
      img {
        border-radius: 30px;
      }
    }
    .testimonials-item-info {
      padding: 0 0 0 30px;
      .name {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
  .testimonials-item-text {
    padding-bottom: 40px;
  }
  .testimonials-item-content {
    @include d-flex();
    justify-content: space-between;
    .icon {
      i {
        font-size: 25px;
        color: var(--Border);
      }
    }
  }
}
.testimonials-item-style-2 {
  background-color: #ffffff;
  padding: 40px 40px 30px 40px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  .testimonials-item-header {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-bottom: 32px;
    .image-wrap {
      img {
        width: 80px;
        height: 80px;
        border-radius: 30px;
      }
    }
  }
  p {
    font-size: 16px;
    color: var(--Primary);
    margin-bottom: 24px;
  }
  .statings-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      font-size: 100px;
      color: var(--Border);
    }
  }
}
// /testimonials-item------------------
