.separator-solid-thin {
  height: var(--dynamic-height);
  width: 1px;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin: 10px 0;
}

.separator-solid-thick {
  height: var(--dynamic-height);
  width: 1px;
  border-right: 3px solid rgba(0, 0, 0, 0.12) !important;
  margin: 10px 0;
}

.separator-dotted-thin {
  height: var(--dynamic-height);
  width: 1px;
  border-right: 2px dotted rgba(0, 0, 0, 0.12) !important;
  margin: 10px 0;
}

.separator-dotted-thick {
  height: var(--dynamic-height);
  width: 1px;
  border-right: 3px dotted rgba(0, 0, 0, 0.12) !important;
  margin: 10px 0;
}
