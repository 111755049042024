.hover-img {
  .image-wrap {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transition: all 1s cubic-bezier(0.3, 1, 0.35, 1) 0s;
      transition: all 1s cubic-bezier(0.3, 1, 0.35, 1) 0s;
      transition: transform 500ms ease;
    }
  }
  &:hover {
    .image-wrap {
      img {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
        -webkit-transition: all 2s cubic-bezier(0.3, 1, 0.35, 2) 0s;
        transition: all 2s cubic-bezier(0.3, 1, 0.35, 2) 0s;
      }
    }
  }
}

.meta {
  @include flex(center, flex-start);
  gap: 10px;
  flex-wrap: wrap;
  .meta-item {
    position: relative;
    @include flex(center, flex-start);
    gap: 7px;
    i {
      font-size: 16px;
    }
    &:not(:last-child) {
      padding-right: 10px;
      &::after {
        position: absolute;
        content: "";
        right: 0;
        width: 1px;
        height: 16px;
        background-color: var(--Border);
      }
    }
  }
  a:hover {
    color: var(--Secondary) !important;
  }
}

.blog-article-item {
  @include d-flex();
  flex-direction: column;
  gap: 11px;
  .article-thumb {
    border-radius: 4px;
    width: 100%;
    height: 260px;
  }
  .article-content {
    @include d-flex();
    flex-direction: column;
    gap: 4px;
  }
  .article-label {
    color: var(--Soft-Text);
  }
  h3 {
    line-height: 36px;
  }
  &.style-row {
    flex-direction: row;
    gap: 40px;
    .article-thumb {
      height: 350px;
      width: 450px;
      flex-shrink: 0;
      width: 47.9%;
    }
    .article-content {
      padding-top: 26px;
      gap: 13px;
      flex-grow: 1;
      > p {
        font-size: 15px;
      }
      > a {
        margin-top: 5px;
      }
    }
    .meta-item {
      color: var(--Soft-Text);
    }
  }
  &.style-large {
    gap: 31px;
    .article-thumb {
      height: unset;
    }
    .article-content {
      gap: 12px;
      .meta {
        margin: 4px 0 5px;
      }
    }
    .meta-item {
      color: var(--Soft-Text);
    }
  }
  &.padding-content {
    border: 1px solid var(--Border);
    border-radius: 12px;
    overflow: hidden;
    .article-thumb {
      border-radius: 0;
    }
    .article-content {
      padding: 0 19px 12px;
    }
  }
  &.style-2 {
    overflow: hidden;
    background-color: var(--White);
    border-radius: 12px;
    box-shadow: 0px 1px 4px 0px rgba(64, 79, 104, 0.05);
    gap: 0;
    .article-thumb {
      border-radius: 0px;
    }
    .article-content {
      padding: 12px 20px;
    }
  }
}
